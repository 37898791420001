<template>
  <div class="questions-container">
    <div
        class="questions"
        v-for="(key, index) in Object.keys(questions)"
        :id="key"
        :key="index"
    >
      <div class="left-wrapper">
        <hr/>
        <router-link :to="getRoute(key)" class="header-nav-link">
          {{ key.replace(/([A-Z])/g, " $1") }}
        </router-link>
      </div>

      <div class="right-wrapper">
        <div class="question" v-for="(faq, i) in questions[key]" :key="i">
          <!-- the 'id' is used to scroll to a certain question, if needed. see the router file -->
          <Collapse
              :id="`collapse-${key}-${faq.id}`"
              :question="faq"
              @click="handleCollapse"
              :questionKey="key"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Collapse from "../components/Collapse";
import {mapStores} from "pinia";
import {useAuthStore} from "@/stores/authStore"
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";

export default {
  name: "Faqs",
  props: {
    openQuestion: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      cms: {
        loaded: false, // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: [],
          LanguageCode: "",
          PageName: "faq",
          MinistryUpdates: false,
        },
        page: {},
      },
      questions: {
        joiningFriends: [
          {
            id: 1,
            question: "Who are Friends of Gideons?",
            collapse: false,
          },
          {id: 2, question: "Who can become a Friend?", collapse: false},
          {
            id: 3,
            question:
                "Can a church, Sunday school class, Bible study, or other group become a Friend?",
            collapse: false,
          },
          {
            id: 4,
            question: "How are Friends different from Gideons?",
            collapse: false,
          },
          {
            id: 5,
            question: "How do Friends support local Gideons?",
            collapse: false,
          },
          {
            id: 6,
            question: "What are the benefits of becoming a Friend?",
            collapse: false,
          },
          {id: 7, question: "How do I get involved?", collapse: false},
        ],
        ministryUpdate: [
          {
            id: 1,
            question: "How often will I receive updates?",
            collapse: false,
          },
          {
            id: 2,
            question: "What if I am new to prayer, how can I get started?",
            collapse: false,
          },
          {
            id: 3,
            question:
                "What if I am new to sharing my faith, how can I get started?",
            collapse: false,
          }
        ],
        giving: [
          {
            id: 1,
            question:
                "How are donations made through the Giving Page impacting the ministry of The Gideons International?",
            collapse: false,
          },
          {
            id: 2,
            question:
                "Are Giving Page donations designated to a specific country?",
            collapse: false,
          },
          {
            id: 3,
            question: "Will I receive a receipt from my donation?",
            collapse: false,
          },
          {
            id: 4,
            question: "How can I set up, change, or stop a monthly donation?",
            collapse: false,
          },
          {
            id: 5,
            question:
                "What are other ways I can financially support The Gideons?",
            collapse: false,
          },
        ],
        store: [
          {
            id: 1,
            question: "Are the Testaments the same as those shared by Gideons?",
            collapse: false,
          },
          {
            id: 2,
            question: "Can Friends purchase full Bibles?",
            collapse: false,
          },
          {
            id: 3,
            question: "Can I order Testaments in other languages?",
            collapse: false,
          },
          {
            id: 4,
            question: "How do I order Friends Testaments?",
            collapse: false,
          },
          {
            id: 5,
            question: "How soon will I receive my order?",
            collapse: false,
          },
          {
            id: 6,
            question: "Is there a way to get a bulk discount for Store orders?",
            collapse: false,
          },
          {
            id: 7,
            question:
                "Can I have my order shipped to a different address than my address?",
            collapse: false,
          },
          {
            id: 8,
            question: "What if I have an issue with my order?",
            collapse: false,
          },
          {
            id: 9,
            question: "What is the policy for refunds and returns?",
            collapse: false,
          },
        ],
        accountInformation: [
          {
            id: 1,
            question:
                "How do I change my email address or contact information?",
            collapse: false,
          },
          {id: 2, question: "How do I reset my password?", collapse: false},
          {id: 3, question: "What if I forgot my username?", collapse: false},
        ],
      },
    };
  },
  components: {
    Collapse,
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore),
  },
  methods: {
    checkIfLoggedIn() {  // remove last 2 entries from 'Ministry Updates' because they contain auth-protected routes
      if (!this.authStore.isLoggedIn) {
        if (this.questions.hasOwnProperty('ministryUpdate')) {
          this.questions.ministryUpdate.splice(0, 2)
        }
      }
    },
    checkIfUSAMember() {
      if (!this.authStore.isUSAMember) {
        if (this.questions.hasOwnProperty('store')) {
          delete this.questions.store
        }
      }
    },
    handleCollapse(question, key) {
      if (!question || !question.id) return false // we need to ignore PointerEvents

      const index = this.questions[key].findIndex(i => i.id == question.id)

      this.questions[key][index].collapse = !this.questions[key][index].collapse
    },
    getRoute(name) {
      return name
          .replace(/([A-Z])/g, " $1")
          .toLowerCase()
          .split(" ")
          .join("-");
    },
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;

              this.setAllCmsContent(results);
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setAllCmsComponentContent(componentContents) {
      this.cms.comp = componentContents;
    },
    getFAQFromCms(page) {
      Object.keys(this.questions).map((key) => {
        this.questions[key] = this.questions[key].map((item) => {
          if (key === "joiningFriends") {
            return {
              id: item.id,
              question: page[`fgFriendsQ${item.id}`],
              answer: page[`fgFriendsA${item.id}`],
              collapse: item.collapse,
            };
          } else if (key === "ministryUpdate") {
            return {
              id: item.id,
              question: page[`fgMinistryQ${item.id}`],
              answer: page[`fgMinistryA${item.id}`],
              collapse: item.collapse,
            };
          } else if (key === "giving") {
            return {
              id: item.id,
              question: page[`fgGivingQ${item.id}`],
              answer: page[`fgGivingA${item.id}`],
              collapse: item.collapse,
            };
          } else if (key === "store") {
            return {
              id: item.id,
              question: page[`fgStoreQ${item.id}`],
              answer: page[`fgStoreA${item.id}`],
              collapse: item.collapse,
            };
          } else if (key === "accountInformation") {
            return {
              id: item.id,
              question: page[`fgAccountQ${item.id}`],
              answer: page[`fgAccountA${item.id}`],
              collapse: item.collapse,
            };
          }
        });
      });
    },
    setAllCmsContent(cmsContent) {
      this.getFAQFromCms(cmsContent);
      this.setTitlePageFromCms(cmsContent);
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgTitle ? page.fgTitle : 'FAQS'
      const pageSubtitle = page && page.fgSubTitle ? page.fgSubTitle : 'Frequently asked questions'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: pageSubtitle,
        img_class: "hero-default-desktop",
        btn_history: false,
      });
    },
  },
  async created() {
    this.setContentLoaded(false);
    this.setTitlePageFromCms()
    this.checkIfUSAMember()
    this.checkIfLoggedIn()
    await this.fetchCmsData();
  },
  mounted() {
    if (this.openQuestion && this.openQuestion === 'return-policy' && this.authStore.isUSAMember) {
      this.handleCollapse({id: 9}, 'store')
    }
  },
};
</script>

<style lang="scss" scoped>
.questions-container {
  padding: 50px 15px;
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;


  .questions {
    display: flex;
    padding-top: 10rem;
    margin-bottom: 0;

    @media screen and (max-width: $md) {
      padding-top: 3rem;
    }

    @media screen and (max-width: $md) {
      flex-wrap: wrap;
    }

    .left-wrapper {
      width: 30%;
      color: #0c2e3c;

      hr {
        height: 7px;
        margin-top: 0;
        background: #0c2e3c;
        @media screen and (max-width: $md) {
          height: 7px;
          background: #0c2e3c;
          margin-top: 100px;
        }
      }

      a {
        text-transform: uppercase;
        font-weight: 500;
        margin-top: 20px;
        color: #0c2e3c;
        font-size: 18px;
        @include MQ(M) {
          font-size: 22px;
        }

        &:active {
          color: #0c2e3c;
        }

        @media screen and (max-width: $md) {
          display: flex;
          justify-content: center;
        }
      }

      @media screen and (max-width: $md) {
        width: 100%;
      }
    }

    .right-wrapper {
      width: 70%;
      padding-left: 13rem;
      color: #0c2e3c;

      .question {
        &:last-child {
          border-bottom: 1px solid #0c2e3c;
        }
      }

      @media screen and (max-width: $xl) {
        padding-left: 5rem;
      }
      @media screen and (max-width: $md) {
        width: 100%;
        padding-left: 0;
        margin-top: 25px;
      }
    }
  }

  .questions:first-child {
    padding-top: 46px;
    margin-top: 0;

    @media screen and (max-width: $md) {
      padding-top: 0;
    }
  }
}

@media screen and (min-width: 770px) {
  :deep p a[href*="tel:"] {
    cursor: default;
    font-weight: normal;
    color: #0c2e3c;
    text-decoration: none !important;
  }
  :deep p a[href*="tel:"]:hover {
    color: #0c2e3c;
  }
}
</style>
